import React, { useRef, useState, useEffect } from "react";
import { CircularProgress, Slider } from "@mui/material";
import ax from "../../../../utils/ax";
import apiBaseURL from "../../../../utils/apiBaseURL";
import extractError from "../../../../utils/extractError";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../CustomButton/CustomButton";
import { saveAs } from "file-saver";
import { Parser } from "json2csv";
import { useSnackbar } from "notistack";
import { useSetRecoilState } from "recoil";
import listState from "../../../../recoil/atoms/list";

const experimentID = 2;
const json2CSVParserConfig = {
	fields: ["u", "v", "f"]
};

const json2CSVParser = new Parser(json2CSVParserConfig);
const defaultDist = {
	object: 20,
	screen: 20
};

function FocalLengthControls() {
	const [objectDist, setObjectDist] = useState(defaultDist.object);
	const [screenDist, setScreenDist] = useState(defaultDist.screen);
	const [exiting, setExiting] = useState(false);
	const [settingDist, setSettingDist] = useState(false);
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const savedParams = useRef([]); // [{objectDist, screenDist, focalLength}]
	const setCurrExp = useSetRecoilState(listState);

	const sendParams = (objectDistToSend, screenDistToSend, isRecal) => {
		setSettingDist(true);

		return ax
			.post(`${apiBaseURL}/experiments/action`, {
				experimentID,
				params: {
					objectDist: objectDistToSend,
					screenDist: screenDistToSend,
					isRecal
				}
			})
			.then((res) => {
				if (!res.data.success) {
					alert(res.data.message || "An error occurred");
				}
			})
			.catch((res) => {
				alert(extractError(res));
				console.error(res);
			})
			.then(() => setSettingDist(false));
	};

	const handleObjectDistChange = (event, newValue) => {
		setObjectDist(newValue);
	};

	const handleScreenDistChange = (event, newValue) => {
		setScreenDist(newValue);
	};

	const getFocalLength = () => {
		return Math.round(100 / (1 / objectDist + 1 / screenDist)) / 100;
	};

	const saveParams = () => {
		savedParams.current.push({
			u: objectDist,
			v: screenDist,
			f: getFocalLength()
		});
		enqueueSnackbar("Parameters saved", { variant: "success" });
	};

	const downloadSavedParams = () => {
		const blob = new Blob([json2CSVParser.parse(savedParams.current)], {
			type: "text/csv;charset=utf-8"
		});
		saveAs(blob, "focal_length_data.csv");
	};

	const recalibrate = () => {
		setObjectDist(defaultDist.object);
		setScreenDist(defaultDist.screen);
		return sendParams(defaultDist.object, defaultDist.screen, true);
	};

	const setDistances = () => {
		sendParams(objectDist, screenDist);
	};

	const exitExperiment = () => {
		setExiting(true);

		recalibrate()
			.then(() => {
				ax.post(`${apiBaseURL}/experiments/leave`, { experimentID })
					.then((res) => {
						if (!res.data.success) {
							alert(res.data.message || "An error occurred");
						}
					})
					.catch((res) => {
						alert(extractError(res));
						console.error(res);
					})
					.then(() => {
						setCurrExp({ experimentID: -1 });
						navigate("/");
					});
			})
			.catch((err) => {
				console.log("An error occurred while recalibrating before exit:", err);
			});
	};
	
	window.addEventListener("unload", (event) => {
        exitExperiment();
    });


	useEffect(() => {
		const handleTabClose = async (event) => {
		  event.preventDefault();
		  console.log('beforeunload event triggered');
		  await ax.post(`${apiBaseURL}/experiments/leave`, { experimentID })
		};
	  
		const handleBeforeUnload = async () => {
		  	await ax.post(`${apiBaseURL}/experiments/leave`, { experimentID })
		};
	  
		window.addEventListener('beforeunload', handleTabClose);
		window.addEventListener('unload', handleBeforeUnload);
	  
		return () => {
		  window.removeEventListener('beforeunload', handleTabClose);
		  window.removeEventListener('unload', handleBeforeUnload);
		};
	  }, []);


	return (
		<>
			<ul role="list" className="divide-y divide-gray-200">
				<li className="py-4">
					<div className="flex space-x-3">
						<div className="flex-1 space-y-1">
							<h3 className="text-sm font-medium">{objectDist} cm</h3>
							<p className="text-sm text-gray-500">Object Distance</p>
							<div className="mx-2">
								<Slider
									aria-label="Object Distance"
									value={objectDist}
									onChange={handleObjectDistChange}
									step={0.1}
									min={4}
									max={45}
									className="text-indigo-600"
								/>
							</div>
						</div>
					</div>
				</li>

				<li className="py-4">
					<div className="flex space-x-3">
						<div className="flex-1 space-y-1">
							<h3 className="text-sm font-medium">{screenDist} cm</h3>
							<p className="text-sm text-gray-500">Screen Distance</p>
							<div className="mx-2">
								<Slider
									aria-label="Object Distance"
									value={screenDist}
									onChange={handleScreenDistChange}
									step={0.1}
									min={4}
									max={45}
									className="text-indigo-600"
								/>
							</div>
						</div>
					</div>
				</li>

				<li className="py-4">
					<div className="flex space-x-3">
						<div className="flex-1 space-y-1">
							<h3 className="text-sm font-medium">{getFocalLength()} cm</h3>
							<p className="text-sm text-gray-500">Focal Length</p>
						</div>
					</div>
				</li>

				<li />
			</ul>

			<CustomButton
				type="primary"
				className="mt-8"
				onClick={setDistances}
				disabled={exiting || settingDist}
			>
				Set Distances
			</CustomButton>
			<div className="flex gap-2">
				<CustomButton
					className="mt-3"
					disabled={exiting || settingDist}
					onClick={saveParams}
				>
					Save Values
				</CustomButton>
				<CustomButton
					className="mt-3"
					disabled={exiting || settingDist}
					onClick={downloadSavedParams}
				>
					Download Values
				</CustomButton>
			</div>

			<CustomButton
				className="mt-3"
				onClick={recalibrate}
				disabled={exiting || settingDist}
			>
				Recalibrate
			</CustomButton>

			<CustomButton
				type="danger"
				className="mt-3"
				disabled={exiting || settingDist}
				onClick={exitExperiment}
			>
				{exiting ? (
					<CircularProgress className="text-red-600" size={20} />
				) : (
					"Exit Experiment"
				)}
			</CustomButton>
		</>
	);
}

export default FocalLengthControls;
